.common-tabs-main .common-tabs-panel {
  padding: 15px 0px;
}

.common-tabs-main .common-tabs-tab {
  font-size: 15px;
  line-height: 20px;
  color: var(--secondary-color);
  font-weight: 400;
  padding: 10px 20px;
}

.common-tabs-main .common-tabs-tab[aria-selected="true"],
.common-tabs-main .common-tabs-tab[data-selected] {
  color: var(--white);
  font-weight: 600;
  background-color: var(--light-purple);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-color: transparent;
}

.common-tabs-main .common-tabs-list {
  border-bottom: 1px solid var(--border-light);
}

.common-tabs-tab svg {
  margin-right: 10px;
}