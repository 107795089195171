@media screen and (min-width: 1250px) and (max-width:1500px) {}

@media (max-width: 1249px) {}

@media (max-width: 1023px) {}

@media screen and (max-width: 991px) {}

@media screen and (min-width: 768px) and (max-width: 991px) {}

@media (max-width: 767px) {
  .auth-form-card {
    max-width: 350px;
    min-width: 350px;
  }

  .auth-content-center {
    padding: 15px 15px;
  }

  .auth-register-profile-flex {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 374px) {
  .auth-form-card {
    max-width: 280px;
    min-width: 280px;
  }
}