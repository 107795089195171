.nav-main .nav-item-button,
.nav-main .nav-item-button:hover {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  padding: 15px;
  color: var(--white);
}

.nav-sub-menu-inner {
  padding-left: 15px;
}

.nav-setting-icon {
  position: absolute;
  top: 15%;
  right: 3%;
  height: 30px;
  width: 30px;
}

.sidebar-nav-icon {
  height: 25px;
  width: 25px;
  margin-right: 5px;
}

.connect-wallet-btn-main .connect-wallet-btn {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  padding: 15px;
  width: 100%;
  background-color: var(--pink);
  border-color: var(--pink);
  color: var(--white);
  border-radius: var(--border-radius-10px);
  -webkit-border-radius: var(--border-radius-10px);
  -moz-border-radius: var(--border-radius-10px);
  -ms-border-radius: var(--border-radius-10px);
  -o-border-radius: var(--border-radius-10px);
  max-width: 200px;
}

.connect-wallet-btn-main {
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
}

.sidebar-btn-flex {
  position: absolute;
  left: 0;
  bottom: 75px;
  width: 250px;
}

.sidebar-btn-flex .sidebar-log-out-btn {
  font-size: 14px;
  padding: 12px;
  background-color: var(--light-purple);
  border-color: var(--pink);
  color: var(--white);
  border-radius: var(--border-radius-10px);
  -webkit-border-radius: var(--border-radius-10px);
  -moz-border-radius: var(--border-radius-10px);
  -ms-border-radius: var(--border-radius-10px);
  -o-border-radius: var(--border-radius-10px);
  width: 100%;
}

.collapse-sub-nav {
  width: 100%;
}

.nav-sub-menu-main .nav-item-button,
.nav-sub-menu-main .nav-item-button:hover {
  background-color: transparent;
  margin: 0;
}