@media (max-width: 1249px) {}

@media (max-width: 1023px) {


      .input-flex {
            flex-direction: column;
      }
}

@media (max-width: 767px) {}

@media (max-width: 549px) {}

@media (max-width: 374px) {}