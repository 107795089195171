.product-text {
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: var(--secondary-color);
}

.product-size-box {
  font-size: 12px;
  background-color: rgb(50, 42, 93);
  color: white;
  padding: 10px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  display: flex;
  gap: 20px;
}

.add-size-button {
  background: var(--pink);
  color: var(--white);
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.add-size-button.disabled {
  background: #65646d;
}