.change-password-container-wrapper {
  max-width: 500px;
  width: 100%;
}

.profile-container {
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 8px;
}

.file-upload-input {
  margin-top: 10px;
  background-color: #221e38;
}

/* .file-upload-input:hover {
  background-color: transparent;
} */

.page-btn-end-flex {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  margin-top: 30px;
}

.dollar-sign {
  position: absolute;
  left: 0;
  top: 0;
}

.input-space-bottom {
  margin-bottom: 15px;
}

.input-group {
  position: relative;
  width: 100%;
}

.input-group .form-input {
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 14px;
  line-height: 20px;
  color: var(--white);
  font-weight: 400;
  border: 0;
  background-color: #221e38;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  width: 100%;
}

.simple-select {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  line-height: 20px;
  color: var(--white);
  font-weight: 400;
  border: 0;
  background-color: #221e38;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.input-select>div {
  background-color: #221e38;
  border: 0;
}

.input-select {
  width: 100%;
}

.input-select div:nth-child(1),
.input-select div:nth-child(1):hover {
  padding: 5px 12px;
  border-radius: 5px;
  font-size: 14px;
  line-height: 20px;
  color: var(--white);
  font-weight: 400;
  border: 0;
  background-color: #221e38;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.input-select div [aria-selected="true"] {
  background-color: #221e38;
}

.filu-upload-input {
  font-size: 14px;
  line-height: 20px;
  color: var(--white);
  font-weight: 400;
}

.file-upload-text {
  font-size: 14px;
  line-height: 20px;
  color: var(--white);
  font-weight: 400;
  z-index: 9;
}

.file-upload-input[type="file"] {
  display: none;
}

.profile-file-upload-main {
  margin-bottom: 20px;
}

.file-upload-main {
  position: relative;
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.file-upload-main .upload-icon,
.file-upload-main svg {
  color: var(--white);
  z-index: 9;
}

.file-upload-main .upload-text {
  font-size: 14px;
  line-height: 20px;
  color: var(--white);
  font-weight: 400;
  z-index: 9;
}

.file-upload-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  z-index: 9;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.input-flex-column {
  display: flex;
  flex-direction: column;
}

.input-space {
  margin: 20px 0px;
}

.show-product-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.input-flex {
  display: flex;
  align-items: center;
  gap: 20px;
}

.change-pass-btn {
  margin-top: 30px;
}

@media (max-width: 1249px) {}

@media (max-width: 1023px) {
  .file-upload-main {
    margin: 0;
  }
}

@media (max-width: 767px) {}

@media (max-width: 549px) {}

@media (max-width: 374px) {}