.sidebar-logo-box {
    padding: 20px;
    width: 100%;
}

.admin-sidebar-icons {
    height: 30px;
    width: 30px;
    color: #80808094;
}

@media (max-width: 1249px) {}

@media (max-width: 1023px) {



}

@media (max-width: 767px) {}

@media (max-width: 549px) {}

@media (max-width: 374px) {}