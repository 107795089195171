.modal-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
    border-bottom: 1px solid var(--border-light);
    margin-bottom: 15px;
    gap: 20px;
}

.modal-close-button {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: flex-end;
}

.modal-close-button svg {
    color: var(--white);
}

.modal-heading {
    font-size: 19px;
    line-height: 24px;
    font-weight: 600;
    color: var(--white);
}