.inner-card-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  background-color: var(--light-purple);
  border-radius: var(--border-radius-10px);
  -webkit-border-radius: var(--border-radius-10px);
  -moz-border-radius: var(--border-radius-10px);
  -ms-border-radius: var(--border-radius-10px);
  -o-border-radius: var(--border-radius-10px);
  padding: 15px;
  min-height: 67px;
}

.post-map {
  height: 41.8rem;
  max-width: 100%;
  /* width        : 80rem; */
  border: 2px solid var(--gray-2);
  border-radius: 1.6rem;
  margin-bottom: 2.4rem;
}