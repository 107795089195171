.modal {
  z-index: 1060;
}

.modal,
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal-backdrop {
  background-color: rgb(0 0 0 / 82%);
}

.modal-dialog {
  min-width: 500px;
  max-width: 1000px;
  background-color: var(--light-purple);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  margin: 15px;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  z-index: 2;
  position: relative;
}

.modal-content {
  padding: 20px;
}

.modal-body {
  overflow-y: auto;
  padding-right: 15px;
  margin-right: -15px;
  padding-left: 5px;
  padding-top: 5px;
  max-height: calc(100vh - 130px);
  /* min-height: calc(100vh - 400px); */
  /* min-height: calc(100vh - 500px); */
  /* max-height: calc(100vh - 300px); */
}

.view-content-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-bottom: 15px;
  flex-wrap: wrap;
}

.view-content-flex:last-child {
  margin-bottom: 0;
}

.view-content-lable {
  font-size: 13px;
  line-height: 17px;
  font-weight: 500;
  color: var(--white);
}

.view-content-value {
  font-size: 13px;
  line-height: 17px;
  font-weight: 500;
  color: var(--secondary-color);
}

.modal-para {
  font-size: 15px;
  line-height: 19px;
  color: var(--secondary-color);
  margin-bottom: 40px;
  text-align: center;
}

.modal-inner-heading {
  font-size: 20px;
  line-height: 25px;
  color: var(--white);
  margin-bottom: 20px;
  text-align: center;
}

.modal-btn-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.modal-delete-btn,
.modal-delete-btn:hover {
  border: 1px solid var(--pink);
  background-color: var(--pink);
  padding: 10px 20px;
  border-radius: 5px;
  color: var(--white);
  font-size: 16px;
  line-height: 21px;
  font-weight: 500;
}

.modal-cacel-btn,
.modal-cacel-btn:hover {
  background-color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  padding: 10px 20px;
  border-radius: 5px;
  color: var(--purple);
  font-size: 16px;
  line-height: 21px;
  font-weight: 500;
}

.modal-spacing-inner {
  padding: 20px;
}