ul,
ol {
  list-style: none;
}

span {
  display: inline-block;
}

a {
  text-decoration: none;
}

svg,
img {
  vertical-align: middle;
}

button {
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

input:focus-visible,
textarea:focus-visible,
select:focus-visible,
button:focus-visible {
  outline: none;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}