section.auth-content-center {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth-link-text {
    font-size: 14px;
    line-height: 20px;
    color: var(--pink);
    font-weight: 400;
    text-decoration: underline;
}

.auth-flex .forgot-pass-text {
    font-size: 14px;
    line-height: 20px;
    color: var(--secondary-color);
    font-weight: 400;
}

.register-title {
    font-size: 20px;
    line-height: 25px;
    color: var(--white);
    margin-bottom: 10px;
}

.auth-register-profile-flex {
    display: flex;
    align-items: center;
    gap: 20px;
}

.register-para {
    font-size: 15;
    line-height: 22px;
    color: var(--white);
    margin-bottom: 0px;
}

.auth-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

.auth-btn-full .secondary-btn {
    width: 100%;
}

.auth-card .auth-title {
    font-size: 20px;
    line-height: 25px;
    color: var(--white);
    margin-bottom: 20px;
}

.auth-logo {
    text-align: center;
    margin-bottom: 20px;
}

.auth-para {
    font-size: 14px;
    line-height: 20px;
    color: var(--secondary-color);
    margin-bottom: 20px;
}

.auth-user-text {
    font-size: 14px;
    line-height: 20px;
    color: var(--secondary-color);
}

.auth-form-card {
    box-shadow: var(--theme-box-shadow);
    background-color: var(--light-purple);
    padding: 25px;
    border-radius: var(--border-radius-10px);
    max-width: 450px;
    min-width: 450px;
    margin: auto;
}

.auth-form-large-card {
    box-shadow: var(--theme-box-shadow);
    background-color: var(--light-purple);
    padding: 25px;
    border-radius: var(--border-radius-10px);
    max-width: 800px;
    min-width: 800px;
    margin: auto;
}

.auth-profile-box {
    box-shadow: var(--theme-box-shadow);
    background-color: var(--light-purple);
    border: 1px solid var(--pink);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    height: 70px;
    max-width: 70px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
}

.auth-scroll-main {
    height: 280px;
    overflow-y: scroll; 
    padding: 10px;
    margin-right: -10px;
    margin-left: -10px;
    /* margin-right: -10px;
    padding-right: 10px; */
}

.scrollbar::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

/* Track */
.scrollbar::-webkit-scrollbar-track {
    background: #6f4ef24d;
}

/* Handle */
.scrollbar::-webkit-scrollbar-thumb {
    background: #6f4ef2;
}

/* Handle on hover */
.scrollbar::-webkit-scrollbar-thumb:hover {
    background: #6f4ef2;
}

.auth-upload-icon {
    height: 30px;
    width: 30px;
    color: var(--pink);
}

.authprofile-img {
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.sent-email-title {
    text-align: center;
    font-size: 31px;
    line-height: 41px;
    color: var(--white);
    margin-bottom: 20px;
}

.sent-email-para {
    text-align: center;
    font-size: 18px;
    line-height: 40px;
    color: var(--secondary-color);
    margin-bottom: 40px;
    max-width: 600px;
    margin: auto;
}

.sent-email-link {
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    color: var(--secondary-color);
    margin-bottom: 30px;
}

.sent-email-btn {
    text-align: center;
    font-size: 17px;
    line-height: 23px;
    text-transform: capitalize;
    color: var(--white);
    background-color: var(--pink);
    padding: 10px 25px;
    border-radius: var(--border-radius-10px);
    -webkit-border-radius: var(--border-radius-10px);
    -moz-border-radius: var(--border-radius-10px);
    -ms-border-radius: var(--border-radius-10px);
    -o-border-radius: var(--border-radius-10px);
}

.back-to-login-btn {
    font-size: 14px;
    line-height: 20px;
    color: var(--secondary-color);
    text-align: center;
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.sent-email-box {
    margin: 40px auto;
    text-align: center;
}

.sent-email-highlight {
    color: var(--pink);
    cursor: pointer;
    text-decoration: underline;
    text-align: center;
    font-size: 18px;
    line-height: 40px;
}

.auth-btn-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
}

.position-relative {
    position: relative;
}

.recover-auth-link-text {
    margin-top: 30px;
    display: block;
}

.instruction-ul {
    margin-top: 30px;
    padding-left: 20px;
}

.instruction-ul li {
    font-size: 14px;
    line-height: 20px;
    list-style: disc;
}

.emeil-steps-ul {
    margin-top: 30px;
    margin-bottom: 30px;
}

.emeil-steps-ul .emeil-steps-li {
    list-style: none;
    font-size: 14px;
    line-height: 20px;
    color: var(--secondary-color);
    margin-bottom: 5px;
}

.success_text {
    color: green;
}

.error_text {
    color: red;
}

.eye-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    height: 20px;
    width: 20px;
    color: var(--secondary-color);
}

.eye-icon-login {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 9;
    color: var(--secondary-color);
}