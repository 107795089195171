.banner-preview-image {
    width: 100%;
    -webkit-transition: all linear 300ms;
    transition: all linear 300ms;
    cursor: pointer;
}

.banner-preview-image img {
    width: 100%;
    height: 100px;
    object-fit: cover;
    -webkit-transition: all linear 300ms;
    transition: all linear 300ms;
    mix-blend-mode: normal;
}

.banner-preview-image img:hover {
    mix-blend-mode: multiply;
}