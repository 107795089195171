.page-title-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  column-gap: 15px;
  row-gap: 10px;
}

.page-right-flex {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.select-input-flex {
  display: flex;
  align-items: center;
  gap: 10px;
}


.date-range-input-main input {
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  color: var(--secondary-color);
  font-weight: 400;
  border: 0;
  background-color: var(--light-purple);
  height: 35px;
}

.date-range-input-main input::placeholder {
  font-size: 14px;
  line-height: 20px;
  color: var(--secondary-color);
  font-weight: 400;
}

.page-title {
  font-size: 22px;
  line-height: 30px;
  font-weight: 600;
  color: var(--white);
}

.page-sub-title-main {
  margin-bottom: 15px;
}

.page-sub-title {
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
  color: var(--secondary-color);
  border-bottom: 1px solid var(--pink);
  max-width: max-content;
  padding-bottom: 8px;
}

.form-lable {
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  color: var(--secondary-color);
  margin-bottom: 10px;
}

.primary-btn-main .primary-btn,
.primary-btn-main .primary-btn:hover {
  background-color: var(--purple);
  color: var(--white);
  padding: 8px 10px;
  border-radius: 5px;
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
}

.secondary-btn-main .secondary-btn,
.secondary-btn-main .secondary-btn:hover {
  background-color: rgb(158 158 158 / 10%);
  color: var(--white);
  padding: 8px 10px;
  border-radius: 5px;
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
}

.dashboard-card-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}

.dashboard-card {
  padding: 20px;
  background-color: var(--light-purple);
  border-radius: var(--border-radius-10px);
  -webkit-border-radius: var(--border-radius-10px);
  -moz-border-radius: var(--border-radius-10px);
  -ms-border-radius: var(--border-radius-10px);
  -o-border-radius: var(--border-radius-10px);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard-card-title {
  font-size: 17px;
  line-height: 22px;
  font-weight: 600;
  color: var(--white);
}

.dashboard-card-value {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: var(--secondary-color);
}

.select-input-main {
  position: relative;
}

.select-dropdown {
  padding: 0px 10px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  color: var(--secondary-color);
  font-weight: 400;
  border: 0;
  background-color: var(--light-purple);
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
}

.select-dropdown div {
  background-color: transparent;
  border: 0;
  font-size: 14px;
  line-height: 20px;
  color: var(--white);
  font-weight: 400;
}

body [role="listbox"] {
  background-color: var(--purple);
  color: #fff;
}

#react-select-2-listbox {
  background-color: var(--purple);
}

#react-select-2-listbox::-webkit-scrollbar {
  width: 2px;
  height: 5px;
}

#react-select-2-listbox::-webkit-scrollbar-track {
  background: var(--secondary-color);
  border-radius: 5px;
}

#react-select-2-listbox::-webkit-scrollbar-thumb {
  background: #88888828;
}

#react-select-2-listbox::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.dash-select-flex {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.payment-lable {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: var(--primary-color);
}

.flex-btn {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

/* .custom-modal .modal-dialog {
  max-width: 42%;
}

.custom-modal .modal-dialog-scrollable .modal-body {
  min-height: 20vh;
} */

.data-flex {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
}

.data-value {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: var(--secondary-color);
}

.data-lable {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: var(--primary-color);
  min-width: 150px;
}

.card-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #dbdbdb;
}

.card-title {
  font-size: 18px;
  line-height: 25px;
  font-weight: 500;
  color: var(--primary-color);
}

.file-upload-input-box {
  margin: 15px 0px;
}

.payment-details-main {
  margin-bottom: 15px;
}

.dash-form-lable {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: var(--secondary-color);
}

.dash-table-scroll {
  height: 53vh;
  overflow-x: auto;
  overflow-y: auto;
}

.page-arrow-flex {
  display: flex;
  align-items: center;
  gap: 5px;
}

.page-arrow-btn {
  padding: 5px;
  color: var(--pink);
}

.page-back-arrow-icon {
  font-size: 20px;
}

.muti-select [role="button"],
.muti-select [role="button"]:hover {
  background-color: #1d1933;
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

.muti-select div {
  align-items: center;
  background-color: transparent;
}


.muti-select div svg {
  color: red;
  height: 20px;
  width: 20px;
}

/* .muti-select div {
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  line-height: 20px;
  color: var(--white);
  font-weight: 400;
  border: 0;
  background-color: #221e38;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
} */

@media (max-width: 1249px) {}

@media (max-width: 1023px) {
  .page-arrow-flex {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 767px) {}

@media (max-width: 549px) {}

@media (max-width: 374px) {}