.header-close-btn svg {
      height: 35px;
      width: 35px;
      color: gray;
}

.header-left-main {
      display: flex;
      align-items: center;
      gap: 20px;
}

.header-logo-flex {
      display: flex;
      align-items: center;
      gap: 90px;
}

.header-main {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      padding: 10px;
      background-color: var(--light-purple);
      z-index: 99;
}

.header-logo {
      height: 45px;
      width: 100%;
      max-width: 120px;
      object-fit: contain;
}

.welcome-text {
      color: var(--secondary-color);
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
}

.seller-name {
      color: var(--white);
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      cursor: pointer;
}

@media (max-width: 1249px) {}

@media (max-width: 1023px) {


      .header-logo-flex {
            gap: 10px;
            justify-content: space-between;
            width: 100%;
      }

      .header-logo {
            height: 35px;
            width: 100%;
      }
}

@media (max-width: 767px) {}

@media (max-width: 549px) {}

@media (max-width: 374px) {}