
.my-store-table-main .page-table-main{
    height: calc(100vh - 150px);
}

.page-table-main {
    background-color: transparent;
    box-shadow: none;
    overflow-x: auto;
    max-width: calc(100vw - 0px);
}

.page-table-main .table {
    min-width: 1000px;
    border-collapse: separate;
    border-spacing: 0px 15px;
}

.page-table-main .table-th {
    font-size: 13px;
    line-height: 17px;
    color: var(--white);
    font-weight: 500;
    padding: 10px 10px 10px 10px;
    border-bottom: 1px solid var(--border-light);
}

.page-table-main .table-td {
    font-size: 15px;
    line-height: 20px;
    color: var(--secondary-color);
    font-weight: 400;
    padding: 8px;
    /* background-color: var(--light-purple); */
    background-color: rgb(29 25 51 / 43%);
}

.table-data-text {
    font-size: 15px;
    line-height: 20px;
    color: var(--secondary-color);
    font-weight: 400;
}

.page-table-main .table-td:first-child {
    border-radius: var(--border-radius-10px) 0px 0px var(--border-radius-10px);
}

.page-table-main .table-td:last-child {
    border-radius: 0px var(--border-radius-10px) var(--border-radius-10px) 0px;
    -webkit-border-radius: 0px var(--border-radius-10px) var(--border-radius-10px) 0px;
    -moz-border-radius: 0px var(--border-radius-10px) var(--border-radius-10px) 0px;
    -ms-border-radius: 0px var(--border-radius-10px) var(--border-radius-10px) 0px;
    -o-border-radius: 0px var(--border-radius-10px) var(--border-radius-10px) 0px;
}

.page-table-main::-webkit-scrollbar {
    width: 2px;
    height: 5px;
}

.page-table-main::-webkit-scrollbar-track {
    background: var(--secondary-color);
    border-radius: 5px;
}

.page-table-main::-webkit-scrollbar-thumb {
    background: #8888885c;
}

.page-table-main::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.green-text {
    color: #0bd00b;
}

.orange-text {
    color: orange;
}

.status-pending-box {
    background-color: rgb(255 165 0 / 10%);
}

.status-approved-box {
    background-color: rgb(11 208 11 / 10%);
}

.red-text {
    color: red;
}

.table-icon {
    height: 25px;
    width: 25px;
}

.table-status-box {
    padding: 4px 10px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    max-width: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table-data-image {
    height: 40px;
    width: 40px;
    object-fit: cover;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.table-data-btn-flex {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}

/* end common table design  */



.data_table_wrapper {
    height: calc(100vh - 20vh);
    background: white;

    border-radius: 0.4rem;
    /* padding      : 1rem; */
    overflow-x: hidden;
    overflow-y: auto;
    border-width: 1px;
    border-color: #e8e8e8;
}

table {
    width: 100%;
    border-spacing: 0;
    position: relative;
}

thead,
tbody,
tfoot,
th,
td {
    font-size: inherit;
    border-width: 0;
    border-style: solid;
}

.dashboard_table thead {
    position: sticky;
    top: 0;
    background: var(--gray-8);
}

.dashboard_table th:first-child,
.dashboard_table td:first-child {
    text-align: left;
}

.dashboard_table th:last-child,
.dashboard_table td:last-child {
    text-align: right;
}

/* .dashboard_table th,
.dashboard_table td {
    max-width: 25rem;
} */

.dashboard_table td {
    font-size: 2rem;
    line-height: 2.5rem;
    color: var(--dark-gray);
    text-align: center;
    /* padding    : 2rem 1rem; */
    padding: 1rem 2rem;
}

.dashboard_table tr {
    transition: all 100ms;
}

.dashboard_table tr:hover {
    background-color: #322A5D30;
    /* padding    : 2rem 1rem; */
}

.dashboard_table td>img {
    width: 100%;
    max-width: 7rem;
}